<template>
  <v-container grid-list-md style="max-width:3840px;">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

      <FiltersPanel v-if="!hideFilters"
                    prefix="documentsFilter"
                    :loading="loading"
                    :is-expansion-panel="false" outlined
                    :items="isShowDocumentsFromOther === 1 ? [filterUsers, filterType] : [filterType]"
                    :key="filterUsers.name + filterUsers.list.length + filterType.name + filterType.list.length"
                    @change="onFilterChanged"
      />

      <v-progress-linear v-if="$root.isMobile && itemsLoading" indeterminate></v-progress-linear>
      <v-list v-if="$root.isMobile">
        <template  v-for="item in items">
          <v-list-item @click="">
            <v-list-item-content>
            <v-list-item-title>
            </v-list-item-title>
            <v-list-item-subtitle>
            </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action></v-list-item-action>
          </v-list-item>
          <v-divider/>
        </template>
        <v-list-item v-if="itemsCount > itemsPerPage && !hidePagination">
          <v-list-item-content>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table v-if="!loading && !error && !$root.isMobile"
                    style="width:100%;"
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="itemsCount"
                    :loading="itemsLoading"
                    :footer-props="footer_props"
                    :items-per-page="itemsPerPage"
                    :show-select="!hideSelect"
                    :hide-default-footer="hidePagination"
                    class="elevation-1"
                    @click:row="onFilePreview($event)">
      >

        <template v-slot:top>
          <v-card flat v-if="!hidePagination">
            <!--<v-toolbar-title>{{ $t('Bids.ListTitle') }}</v-toolbar-title>-->
            <v-spacer></v-spacer>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
            <v-spacer></v-spacer>
          </v-card>
        </template>

        <template v-slot:footer="{ props: { options: { page }} }">
          <Pagination v-if="itemsCount > itemsPerPage && !hidePagination"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="pageChanged"></Pagination>
        </template>
        <template v-slot:item.id="{ item }">
          #{{item.id}}
        </template>
        <template v-slot:item.created="{ item }">
          {{item.createdPST}}
        </template>
        <template v-slot:item.user="{ item }">
            <UserSnippet :avatar-only="false"
                         :user-data="item.userData"
                         :small="true"
                         :overrideClick="true"
                         :override-tooltip="item.userData.firstName + ' ' + item.userData.lastName"
                         @click="/*filter by user*/"
            />
            <!--
            <v-tooltip bottom v-if="!showDispatchers">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">mdi-face-agent</v-icon>
              </template>
              <span>${{dispatcher.totalRate}}</span>
            </v-tooltip>
            -->
        </template>
        <template v-slot:item.size="{ item }">
          {{$root.fileSizeString(item.size, true)}}
        </template>
        <template v-slot:item.linked="{ item }">
          <template v-if="item.user_id">
            <a @click.stop="$root.route('/users/page/' + item.user_id)">{{item.linkedUserFirstName}}&nbsp;{{item.linkedUserLastName}}</a><br/>
          </template>
          <template v-if="item.bid_id">
            <a @click.stop="$root.route('/bids/edit/' + item.bid_id)">{{$t('Bids.Bid')}}&nbsp;#{{item.bid_id}}</a><br/>
          </template>
          <template v-if="item.load_id">
            <a @click.stop="$root.onShowLoadPreview(item.load_id)">{{$t('Load')}}&nbsp;#{{item.load_id}}</a><br/>
          </template>
          <template v-if="item.invoice_id">
            <a @click.stop="$root.onShowInvoicePreview(item.invoice_id, item.invoiceNumber)">{{$t('Invoice')}}&nbsp;#{{item.invoiceNumber}}</a>
          </template>
          <template v-if="item.truck_id">
            <a @click.stop="$root.onShowTruckPreview(item.truck_id, item.truckName)">{{$t('Truck')}}&nbsp;{{item.truckName}}</a>
          </template>
        </template>
        <template v-slot:item.folder="{ item }">
          <v-chip v-if="item.folder.indexOf('avatar') === 0" @click="() => {}">User Photo</v-chip>
          <v-chip v-else-if="item.folder.indexOf('rateCon') === 0" @click="() => {}">Rate Con</v-chip>
          <v-chip v-else-if="item.folder.indexOf('proofOfDelivery') === 0">Prof Of Delivery</v-chip>
          <v-chip v-else-if="item.folder.indexOf('invoiceDocumentsUPLOAD') === 0">Invoice Attachment</v-chip>
          <v-chip v-else-if="item.folder.indexOf('loadPhotos') === 0">Load Photos</v-chip>
          <v-chip v-else-if="item.folder.indexOf('billOfLading') === 0" @click.stop="() => {}">Bill Of Landing</v-chip>
          <v-chip v-else-if="item.folder.indexOf('annualInspection') === 0">Annual Inspection</v-chip>
          <v-chip v-else-if="item.folder.indexOf('brokerSetupPackage') === 0">Broker Setup Package</v-chip>
          <v-chip v-else-if="item.folder.indexOf('agreement') === 0">Agreement</v-chip>
          <v-chip v-else-if="item.folder.indexOf('W9Form') === 0">W9 Form</v-chip>
          <v-chip v-else-if="item.folder.indexOf('paymentDetails') === 0">Payment Details</v-chip>
          <v-chip v-else-if="item.folder.indexOf('publicRateCon') === 0">Public RateCon</v-chip>
          <v-chip v-else-if="item.folder.indexOf('check') === 0">Payment Check</v-chip>
          <v-chip v-else-if="item.folder.indexOf('truckInsurance') === 0">Truck Insurance</v-chip>
          <v-chip v-else-if="item.folder.indexOf('deliveryOrder') === 0">Delivery Order</v-chip>
          <v-chip v-else-if="item.folder.indexOf('odometerStart') === 0">Odometer Start</v-chip>
          <v-chip v-else-if="item.folder.indexOf('odometer') === 0">Odometer End</v-chip>
          <v-chip v-else-if="item.folder.indexOf('emptyScaleTicket') === 0">Empty Scale Ticket</v-chip>
          <v-chip v-else-if="item.folder.indexOf('truckAvatar') === 0">Truck Photo</v-chip>
          <v-chip v-else-if="item.folder.indexOf('truckRegistration') === 0">Truck Registration</v-chip>
          <v-chip v-else-if="item.folder.indexOf('maintenanceAtt') === 0">Truck Maintenance</v-chip>
          <v-chip v-else-if="item.folder.indexOf('tasksAtt') === 0">Task Attachment</v-chip>
          <v-chip v-else>{{item.folder}}</v-chip>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="previewModal">
      <v-card  :style="{maxHeight: $root.isMobile ? '500px' : '800px', overflow: 'hidden'}">
        <v-card-title>
          <h4>{{selectedFileName}}</h4>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="previewModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <FileUpload
            :key="selectedFileHash"
            :pickOnMount="false"
            :disabled="true"
            :deleteAllowed="false"
            :height="$root.isMobile ? 500 : 800"
            :downloadAllowed="true"
            :send-via-email-allowed="true"
            :readonly="true"
            :url="selectedFileUrl"
            :downloadUrl="selectedFileUrl"
            :field="selectedFileHash"
            :filename="selectedFileName"
            :uploadFolder="selectedFileFolder"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import documentsMixin from './../../mixins/documentsMixin';
import Pagination from './../../components/Pagination';
import UserSnippet from '../../components/UserSnippet';
import FileUpload from '../../components/FileUpload';
import FiltersPanel from '../../components/FiltersPanel';

export default {
  components: { FiltersPanel, UserSnippet, Pagination, FileUpload },
  mixins: [documentsMixin, fieldAccessRightMixin],
  props: ['responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideSelect', 'hideReportMode', 'hideTotals'],
  data () {
    return {
      polling: null,
      loading: true,
      error: '',
      itemsPerPage: 25,
      page: 1,
      itemsLoading: false,
      options: {},
      awaitingSearch: false,
      search: '',
      initialHeaders: [],
      headers: [],
      filtersPanel: 0,
      itemsCount: 0,
      items: [],
      selectedItem: {},
      previewModal: false,
      selectedFileFolder: '',
      selectedFileHash: '',
      selectedFileName: '',
      selectedFileUrl: '',
      filterUsersValue: 0,
      filterTypeValue: 'All',
      isShowDocumentsFromOther: 0,
      footer_props: {
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': '',
        'disable-items-per-page': false
      },
      selected: -1,
      // Filters
      filterUsers: {
        name: 'filterUser',
        label: 'User',
        field: '',
        list: []
      },
      filterType: {
        name: 'filterType',
        label: 'Type',
        field: '',
        list: [
          { id: '', name: 'All' },
          { id: 'avatar', name: 'User Photo' },
          { id: 'rateCon', name: 'Rate Con' },
          { id: 'proofOfDelivery', name: 'Prof Of Delivery' },
          { id: 'invoiceDocumentsUPLOAD', name: 'Invoice Attachment' },
          { id: 'loadPhotos', name: 'Load Photos' },
          { id: 'billOfLading', name: 'Bill Of Landing' },
          { id: 'annualInspection', name: 'Annual Inspection' },
          { id: 'brokerSetupPackage', name: 'Broker Setup Package' },
          { id: 'agreement', name: 'Agreement' },
          { id: 'W9Form', name: 'W9 Form' },
          { id: 'paymentDetails', name: 'Payment Details' },
          { id: 'publicRateCon', name: 'Public RateCon' },
          { id: 'check', name: 'Payment Check' },
          { id: 'truckInsurance', name: 'Truck Insurance' },
          { id: 'deliveryOrder', name: 'Delivery Order' },
          { id: 'odometerStart', name: 'Odometer Start' },
          { id: 'odometer', name: 'Odometer End' },
          { id: 'emptyScaleTicket', name: 'Empty Scale Ticket' },
          { id: 'truckAvatar', name: 'Truck Photo' },
          { id: 'truckRegistration', name: 'Truck Registration' },
          { id: 'maintenanceAtt', name: 'Truck Maintenance' },
          { id: 'tasksAtt', name: 'Tasks Attachment' }
        ]
      }
    };
  },
  mounted () {
    this.initHeaders();
    this.$root.setLastListPath(this.$route.path);
    this.$nextTick(() => {
      this.bidPostedLoadDispatcher = this.$store.getters.currentUser.linkedToDispatcher;
      // set saved items per page if exist
      if (this.$root.getSessionStorageItem('documentsItemsPerPage')) {
        this.itemsPerPage = parseInt(this.$root.getSessionStorageItem('documentsItemsPerPage'));
      }
      // set saved page if exist
      if (this.$root.getSessionStorageItem('documentsPage')) {
        this.options.page = parseInt(this.$root.getSessionStorageItem('documentsPage'));
        this.page = parseInt(this.$root.getSessionStorageItem('documentsPage'));
      }

      // do not call fetch here and disable loading sinse in will called when data-table initialize and options changed in watch
      // Only for small screen since we use simple list there
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.retrieveCurrentGeolocation();
        this.fetchData();
        this.filtersPanel = 1;
        this.filtersPanel = 1;
      }
      this.loading = false;
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.retrieveCurrentGeolocation();
        this.fetchData(false);
      }, this.$store.getters.pullIntervalList);
    });

    this.$root.loadUsersList(null, this.$store.getters.roleBroker, true, true).then(response => {
      if (response.status === 'ok') {
        this.filterUsers.list = [{ id: '', name: 'All' }];
        for (let k in response.result.data) {
          this.filterUsers.list.push({
            id: response.result.data[k].id,
            name: response.result.data[k].firstName + ' ' + response.result.data[k].lastName
          });
        }
      }
    });

    this.$root.onApplyEditColumns = () => {
      this.initHeaders();
      if (this.$root.getLocalStorageItem(this.editColumnsName)) {
        const columnSettings = JSON.parse(this.$root.getLocalStorageItem(this.editColumnsName));
        this.headers = this.headers.filter((e, i) => { return columnSettings.indexOf(i) >= 0 });
      }
    };

    this.$root.onApplyEditColumns();
  },
  beforeDestroy () {
    this.$root.contextCreate = () => {};
    clearInterval(this.polling);
  },
  computed: {
    editColumnsName () {
      return 'LoadsFeedColumns';
    }
  },
  watch: {
    search () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1;
          this.page = 1;
          this.fetchData();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (itemsPerPage) {
          this.$root.setSessionStorageItem('documentsItemsPerPage', itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        }
        if (page) {
          this.$root.setSessionStorageItem('documentsPage', page);
        }
        this.fetchData().then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  methods: {
    onFilterChanged (e) {
      this.filterUsersValue = 0;
      this.filterTypeValue = 'All';
      if (e.filterUser !== '') {
        this.filterUsersValue = parseInt(e.filterUser);
      }
      if (e.filterType !== '') {
        this.filterTypeValue = e.filterType;
      }
      this.fetchData();
    },
    onFilePreview (item) {
      this.selectedFileFolder = item.folder.indexOf('/') > 0 ? item.folder.substring(0, item.folder.length - 1) : item.folder;
      this.selectedFileHash = item.hash;
      this.selectedFileName = item.filename;
      this.selectedFileUrl = this.$root.getImageUrl(this.selectedFileFolder, item.hash, item.filename);
      this.previewModal = true;
    },
    onTableEdit () {
      this.$root.onEditColumnsModal(this.initialHeaders, this.editColumnsName);
    },
    retrieveCurrentGeolocation () {
      // Retrieve current device location
      if (this.$store.getters.isUserRoleDriverOO && this.isGeolocationSupported) {
        this.$root.getLocation().then(result => {
          if (result.status === 'ok') {
            this.driverCurrentLocation.lat = result.lat;
            this.driverCurrentLocation.lng = result.lng;
          } else {
            this.isGeolocationSupported = false;
            this.$root.toast(result.msg, { color: 'error' });
          }
        });
      }
    },
    onShowPostedLoad (item) {
      this.selectedPostedLoad = item;
      this.selectedDeadheadMiles = '';

      this.retrieveCurrentGeolocation();

      let currnetLocation = { lat: this.driverCurrentLocation.lat, lng: this.driverCurrentLocation.lng }; //{ lat: 34.0522, lng: -118.2437 };

      this.selectedDeadheadMilesLoading = true;
      this.$root.calcDistance([
        currnetLocation,
        { lat: parseFloat(item.pickupLat), lng: parseFloat(item.pickupLng) }
      ]).then((response) => {
        if (response.routes && response.routes.length > 0 && response.routes[0].legs) {
          const legs = response.routes[0].legs;
          if (legs && legs.length > 0) {
            const _duration = this.$root.isUseGoogleMaps ? (legs[0].duration_in_traffic ? legs[0].duration_in_traffic.value : legs[0].duration.value) : legs[0].duration;
            const _distance = this.$root.isUseGoogleMaps ? legs[0].distance.value : legs[0].distance;
            if (_duration && _distance) {
              this.selectedDeadheadMiles = this.$root.metersToMiles(_distance).toLocaleString();
            }
          }
          this.selectedDeadheadMilesLoading = false;
        }
      }).catch(() => {
        this.selectedDeadheadMiles = 'Error';
      }).finally(() => {
        this.selectedDeadheadMilesLoading = false;
      });

      this.postedLoadModal = true;
    },
    onBidPostedLoad () {
      this.makeBidFromPostedLoad = true;
    },
    initHeaders () {
      this.initialHeaders = [
        { text: '#', value: 'id', sortable: false },
        { text: this.$root.t('Documents.Created'), value: 'created', sortable: false },
        { text: this.$root.t('Documents.User'), value: 'user', sortable: false },
        { text: this.$root.t('Documents.Type'), value: 'folder', sortable: false },
        { text: this.$root.t('Documents.Name'), value: 'filename', sortable: false },
        { text: this.$root.t('Documents.Size'), value: 'size', sortable: false },
        { text: this.$root.t('Documents.Linked'), value: 'linked', sortable: false },
        { text: '', value: 'actions', sortable: false }];
      this.headers = this.initialHeaders;
    },
    pageChanged (event) {
      this.options.page = event;
      this.page = event;
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.fetchData();
      }
    },
    fetchData (showLoading = true) {
      if (!this.isDocumentsViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      // clear filters
      return new Promise(async (resolve, reject) => {
        if (showLoading) this.itemsLoading = true;
        let { page, itemsPerPage } = this.options;
        this.error = null;
        var _newItems = [];
        let itemsPage = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? this.page - 1 : page - 1;
        let itemsPP = itemsPerPage < 0 ? 0 : itemsPerPage;

        // let orderField = (this.options.sortBy && this.options.sortBy[0]) ? this.options.sortBy[0] : null;
        // let orderDesc = (this.options.sortDesc && this.options.sortDesc[0]) ? this.options.sortDesc[0] : null;
        let response = {};
        if (this.responseOverride) {
          response = this.responseOverride;
        }
        else {
          response = await this.getDocumentsList(itemsPage, itemsPP, this.filterUsersValue, this.filterTypeValue);
        }

        if (response.status && response.status === 'error') {
          console.error('ERROR', response.msg);
          reject(response.msg);
        }

        this.isShowDocumentsFromOther = response.isShowDocumentsFromOther;

        _newItems = [];
        if (response.status === 'ok') {
          for (let i = 0; i < response.result.length; i++) {
            let _item = response.result[i];
            _item.userData = {
              id: _item.userId,
              firstName: _item.userFirstName,
              lastName: _item.userLastName,
              avatarUPLOAD: _item.userAvatarUPLOAD
            };
            _newItems.push(_item);
          }
          this.items = _newItems;
          this.itemsCount = response.count;
          resolve({ items: this.items, total: this.itemsCount });
        }

        if (showLoading) this.itemsLoading = false;
        this.loading = false;
      });
    }
  }
};
</script>
