<template>
  <v-container grid-list-md style="max-width:3840px;">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-layout v-if="!hideFilters">
        <v-flex xs12>
          <FiltersPanel
            ref="filtersPanel"
            v-if="!hideFilters"
            prefix="bidsList"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @filterBrokerKeyUp="filterBrokerSearch"
            @change="onFilterChanged"/>

        </v-flex>
      </v-layout>
      <v-card>
        <v-card-title v-if="!hideTitle">
          <v-btn
            class="ma-1"
            v-if="$root.isMobile"
            icon
            @click="$refs.filtersPanel.showFiltersDrawer()">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <h2>Bids List</h2>
          <v-btn small color="success" class="ml-2" @click="$root.route('/bids/create')" v-if="$root.isBidsCreateAllowed">
            <v-icon left>mdi-plus</v-icon>{{$t('Create')}}
          </v-btn>
          <v-spacer></v-spacer>
          <!--<v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>-->
        </v-card-title>
      </v-card>

      <v-progress-linear v-if="loading && !error && $root.isMobile" indeterminate/>
      <v-list three-line v-if="!error && $root.isMobile">
        <!--<v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
          </v-list-item-content>
        </v-list-item>-->
        <template v-for="(item) in items">
          <v-list-item
            :key="'bid' + item.id"
            @click="onBidClick(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-html="'<b>' + item.dispatcherName + '<br/>' + (item.brokerName || '') + '</b>'"></v-list-item-title>
              <v-list-item-title style="white-space: normal;">
                {{item.firstLocation}}
                <v-divider v-if="item.lastLocation.length > 0"/>
                {{item.lastLocation}}
              </v-list-item-title>
              <v-list-item-subtitle><b>{{$t('Created')}}:</b>&nbsp;{{item.createdShortDatePST}}&nbsp;{{item.createdTimePST}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="text-right">
              #{{item.id}}
              <br/>
              {{item.totalDistance}}
              <br/>
              {{item.totalRate}}
              <v-chip small>
                <v-icon v-if="item.isNewMails" color="warning" left>mdi-new-box</v-icon>
                <v-icon v-else-if="item.status === 'pending'" color="warning" small>mdi-timer-sand</v-icon>
                <v-icon v-else-if="item.status === 'sent'" color="success" small>mdi-email-check</v-icon>
                <v-icon v-else-if="item.status === 'to sent'" color="warning" small>mdi-email-send</v-icon>
                <v-icon v-else small>mdi-email</v-icon>
                {{item.mailsCount}}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider/>
        </template>
        <v-list-item v-if="itemsCount > itemsPerPage && !hidePagination">
          <v-list-item-content>
            <Pagination v-if="itemsCount > itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table v-if="!error && !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                    style="width:100%;"
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="itemsCount"
                    :loading="loading"
                    :footer-props="footer_props"
                    :items-per-page="itemsPerPage"
                    :show-select="!hideSelect"
                    :hide-default-footer="hidePagination"
                    item-key="id"
                    class="elevation-1"
                    @click:row="onBidClick($event)"
      >

        <template v-slot:top>
          <v-card flat>
            <!--<v-toolbar-title>{{ $t('Bids.ListTitle') }}</v-toolbar-title>-->
            <v-spacer></v-spacer>
            <Pagination v-if="itemsCount > itemsPerPage && !hidePagination"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
            <v-spacer></v-spacer>
          </v-card>
        </template>

        <template v-slot:footer="{ props: { options: { page }} }">
          <Pagination v-if="itemsCount > itemsPerPage && !hidePagination"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="pageChanged"/></Pagination>
        </template>

        <template v-slot:item.id="{ item }">
          #{{item.id}}
        </template>
        <template v-slot:item.status="{ item }">
          <template v-if="parseInt(item.deleted) === 0">
            <v-chip>
              <v-avatar left>
                <v-icon v-if="item.isNewMails" color="warning">mdi-new-box</v-icon>
                <v-icon v-else-if="item.status === 'pending'" color="warning" small>mdi-timer-sand</v-icon>
                <v-icon v-else-if="item.status === 'sent'" color="success" small>mdi-email-check</v-icon>
                <v-icon v-else-if="item.status === 'to sent'" color="warning" small>mdi-email-send</v-icon>
                <v-icon v-else small>mdi-email</v-icon>
              </v-avatar>
              {{item.mailsCount}}
            </v-chip>
          </template>
          <template v-else>
            <v-icon color="error">mdi-delete-circle</v-icon>
          </template>
          <v-btn small icon @click="$root.route('/loads/page/'+item.loadId)" v-if="item.loadId">
            <v-icon small color="success">mdi-dolly</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.dispatcherName="{ item }">
          <div class="text-no-wrap">
            <v-tooltip bottom v-if="item.creatorInitials && item.createdBy !== item.dispatcher">
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on"
                          style="cursor: pointer;"
                          class="mr-1"
                          :size="26"
                          :color="(item.creatorInitials && !item.creatorAvatar)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', item.creatorAvatar)" v-if="item.creatorAvatar"></v-img>
                  <span class="white--text" v-else-if="item.creatorInitials">{{item.creatorInitials}}</span>
                  <v-icon large v-else>account_circle</v-icon>
                </v-avatar>
              </template>
              <span>{{item.creatorName}}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on"
                          @click.stop="$root.isAccessRightAllowView($store.getters.menuRights['Dispatcher']) ? $root.onShowUserPreview(item.dispatcher, item.dispatcherName) : null"
                          style="cursor: pointer;"
                          class="mr-1"
                          :size="26"
                          :color="(item.dispatcherInitials && !item.dispatcherAvatar)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', item.dispatcherAvatar)" v-if="item.dispatcherAvatar"></v-img>
                  <span class="white--text" v-else-if="item.dispatcherInitials">{{item.dispatcherInitials}}</span>
                  <v-icon large v-else>account_circle</v-icon>
                </v-avatar>
              </template>
              <span>{{item.dispatcherName}}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.brokerName="{ item }">
          <template v-if="item.broker > 0 && item.brokerName !== ''">
            <a @click.stop="$root.route('/user/page/' + item.broker)">{{item.brokerName}}</a>
            <br/>
            <small v-if="item.brokerManager && !$vuetify.breakpoint.xs">{{item.brokerManagerName}}</small>
          </template>
          <template v-else-if="item.recipient">
            <a :href="'mailto:'+item.recipient" @click.stop="" target="_blank">{{item.recipient}}</a>
            <v-btn small icon @click.stop="$root.copyToClipboard(item.recipient)"><v-icon small>mdi-content-copy</v-icon></v-btn>
          </template>
          <template v-else>
            N/A
          </template>
        </template>
        <template v-slot:item.subject="{ item }">
          {{item.firstLocation}}
          <v-divider v-if="item.lastLocation.length > 0"/>
          {{item.lastLocation}}
        </template>
        <template v-slot:item.actions="{ item }" v-if="$root.isOnline && $root.isBidsEditAllowed">
          <v-layout>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon small v-on="on" @click="$root.route('/bids/edit/' + item.id)">
                    <v-icon small class="mr-1">mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('Bids.Edit')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.stop="$root.addNewPageTab('/bids/edit/' + item.id)" v-if="$root.isOnline && $root.isBidsEditAllowed">
                      <v-icon small class="mr-1">mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
                <span>{{$t('OpenExternal')}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-tooltip bottom v-if="$root.isOnline && $root.isLoadsCreateAllowed">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="item.loadId > 0 ? $root.route('/loads/page/' + item.loadId) : $root.onCreateLoadBtn(item.id)">
                    <v-icon small class="mr-1" :color="item.loadId > 0 ? 'success' : null">mdi-dolly</v-icon>
                  </v-btn>
                </template>
                <span>{{item.loadId > 0 ? $t('GoToLoad') : $t('Bids.BidsCreateLoad')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex>
              <v-tooltip bottom v-if="$root.isOnline && $root.isBidsEditAllowed">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="parseInt(item.deleted) === 0 ? deleteBid(item.id) : restoreBid(item.id)">
                    <v-icon small class="mr-1" color="error" v-html="parseInt(item.deleted) === 0 ? 'mdi-delete' : 'mdi-delete-off'">mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>{{parseInt(item.deleted) === 0 ? $t('Bids.DeleteBid') : $t('Bids.RestoreBid')}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import Pagination from '../../components/Pagination';
import FiltersPanel from '../../components/FiltersPanel';

export default {
  components: { Pagination, FiltersPanel },
  props: ['hideFilters', 'responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideSelect', 'hideReportMode', 'hideTotals'],
  mixins: [fieldAccessRightMixin],
  data () {
    return {
      polling: null,
      loading: true,
      error: '',
      itemsPerPage: 25,
      page: 1,
      options: {},
      headers: [
        { text: '#', value: 'id', sortable: false },
        { text: this.$root.t('Bids.ListStatus'), value: 'status', sortable: false },
        { text: this.$root.t('Bids.ListCreated'), value: 'date', sortable: false },
        { text: this.$root.t('Bids.ListCreatorDispatcher'), value: 'dispatcherName', sortable: false },
        { text: this.$root.t('Bids.ListBroker'), value: 'brokerName', sortable: false },
        { text: this.$root.t('Bids.ListSubject'), value: 'subject', sortable: false },
        { text: this.$root.t('Bids.ListDistance'), value: 'totalDistance', sortable: false },
        { text: this.$root.t('Bids.ListDistanceToPickup'), value: 'distanceToPickup', sortable: false },
        { text: this.$root.t('Bids.ListRatePerMile'), value: 'ratePerMile', sortable: false },
        { text: this.$root.t('Bids.ListRate'), value: 'totalRate', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      filtersPanel: 1,
      itemsCount: 0,
      items: [],
      filterDispatcher: 0,
      filterBroker: 0,
      hideDeactivated: true,
      deletedFilter: 0,
      filterStatus: 0,
      filterTruck: 0,
      deletedFilterItems: [
        { value: 0, text: 'Not deleted' },
        { value: 1, text: 'Deleted' },
        { value: 2, text: 'All' }],
      footer_props: {
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': '',
        'disable-items-per-page': false
      },
      isFilterInitialized: false,
      filtersKey: new Date().getTime(),
      filtersItems: [
        {
          name: 'deletedFilter',
          label: 'Deleted',
          field: 0,
          list: [
            { id: 0, name: 'Not deleted' },
            { id: 1, name: 'Deleted' },
            { od: 2, name: 'All' }
          ]
        },
        {
          name: 'filterStatus',
          label: 'Status',
          field: '',
          multiple: false,
          list: [
            { id: 0, name: 'All' },
            { id: 1, name: 'With Load' },
            { id: -1, name: 'Without Load' }
          ]
        },
        {
          name: 'filterDispatcher',
          label: 'Dispatcher',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterTruck',
          label: 'Truck',
          fields: '',
          multiple: true,
          list: [
          ]
        },
        {
          name: 'filterBroker',
          label: 'Broker',
          fields: '',
          multiple: true,
          list: [
          ]
        }
      ]
    };
  },
  mounted () {
    this.$root.setLastListPath(this.$route.path);
    this.$nextTick(() => {
      this.$root.contextCreate = () => {
        this.$root.route('/bids/create');
      };
      // set saved items per page if exist
      if (this.$root.getLocalStorageItem('bidsItemsPerPage')) {
        this.itemsPerPage = parseInt(this.$root.getLocalStorageItem('bidsItemsPerPage'));
      }
      // set saved page if exist
      if (this.$root.getLocalStorageItem('bidsPage')) {
        this.options.page = parseInt(this.$root.getLocalStorageItem('bidsPage'));
        this.page = parseInt(this.$root.getLocalStorageItem('bidsPage'));
      }
      /*// set saved dispatcher filter
      if (this.$root.getLocalStorageItem('bidsFilterDispatcher')) {
        this.filterDispatcher = parseInt(this.$root.getLocalStorageItem('bidsFilterDispatcher'));
      }
      // set saved broker filter
      if (this.$root.getLocalStorageItem('bidsFilterBroker')) {
        this.filterBroker = parseInt(this.$root.getLocalStorageItem('bidsFilterBroker'));
      }*/

      // do not call fetch here and disable loading sinse in will called when data-table initialize and options changed in watch
      // Only for small screen since we use simple list there
      if (this.$root.isMobile) {
        this.fetchData();
      }
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData(false);
      }, this.$store.getters.pullIntervalList);
    });
  },
  beforeDestroy () {
    this.$root.contextCreate = () => {};
    clearInterval(this.polling);
  },
  watch: {
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (itemsPerPage) {
          this.$root.setLocalStorageItem('bidsItemsPerPage', itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        }
        if (page) {
          this.$root.setLocalStorageItem('bidsPage', page);
        }
        this.fetchData().then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  methods: {
    filterBrokerSearch (val) {
      if (!val || val.length === 0) return;
      this.$root.loadUsersList(this.$store.getters.roleBroker, false, true, 1, val).then(response => {
        if (response.status === 'ok') {
          this.filterBrokersList = response.result.data.map((item) => {
            return {
              id: item.id,
              name: item.firstName + ' ' + item.lastName
            };
          });
          this.$refs.filtersPanel.filters.forEach(el => {
            if (el.name === 'filterBroker') {
              el.list = this.filterBrokersList;
            }
          });
        }
      });
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.options.page = 1;
        this.page = 1;
        this.fetchData();
      }
      this.isFilterInitialized = true;
    },
    onBidClick (item) {
      if (this.$root.isAccessRightAllowEdit(this.$store.getters.menuRights['Bids'])) {
        this.$root.route('/bids/edit/' + item.id);
      }
    },
    pageChanged (event) {
      this.options.page = event;
      this.page = event;
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.fetchData();
      }
    },
    filterDispatcherChanged () {
      if (this.filterDispatcher > 0) {
        this.$root.setLocalStorageItem('bidsFilterDispatcher', this.filterDispatcher);
      } else {
        this.$root.removeFromLocalStorageItem('bidsFilterDispatcher');
      }
      this.fetchData();
    },
    filterBrokerChanged () {
      if (this.filterBroker > 0) {
        this.$root.setLocalStorageItem('bidsFilterBroker', this.filterBroker);
      } else {
        this.$root.removeFromLocalStorageItem('bidsFilterBroker');
      }

      this.fetchData();
    },
    deleteBid (id) {
      this.$dialog.confirm(this.$root.t('Bids.BidDeleteConfirmationMsg')).then(dialog => {
        this.$root.deleteBid(id);
        this.fetchData();
      });
    },
    restoreBid (id) {
      this.$dialog.confirm(this.$root.t('Bids.BidRestoreConfirmationMsg')).then(dialog => {
        this.$root.restoreBid(id);
        this.fetchData();
      });
    },
    fetchData (showLoading = true) {
      let isFilterDataUpdated = false;
      if (!this.isBidsViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      return new Promise(async (resolve, reject) => {
        if (showLoading) this.loading = true;
        let { page, itemsPerPage } = this.options;
        this.error = null;
        let _newItems = [];

        let itemsPage = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? this.page - 1 : page - 1;
        let itemsPP = itemsPerPage < 0 ? 0 : itemsPerPage;
        let isShowOnlyToday = this.$route.params.showOnlyToday ? 1 : 0;

        // let orderField = (this.options.sortBy && this.options.sortBy[0]) ? this.options.sortBy[0] : null;
        // let orderDesc = (this.options.sortDesc && this.options.sortDesc[0]) ? this.options.sortDesc[0] : null;
        let response = {};
        if (this.responseOverride) {
          response = this.responseOverride;
        }
        else {
          response = await this.$root.getBidsList(this.deletedFilter,
            itemsPage || 0,
            itemsPP || this.itemsPerPage,
            this.filterDispatcher, this.filterBroker, this.filterTruck, this.filterStatus, isShowOnlyToday
            /* this.$route.params.role,
            page?page:1,
            orderField,
            orderDesc */);
        }
        if (response.status && response.status === 'error') {
          console.error('ERROR', response.msg);
          if (showLoading) this.itemsLoading = false;
          this.loading = false;
          reject(response.msg);
        }
        else if (response.status === 'ok') {
          for (let i = 0; i < response.result.length; i++) {
            let managerName = '';
            let managerPhone = '';
            if (response.result[i].brokerManager) {
              const managers = JSON.parse(response.result[i].brokerManagersJSON);
              if (managers) {
                managers.forEach((item) => {
                  if (item.email === response.result[i].brokerManager) {
                    managerName = item.name;
                    managerPhone = item.phone;
                  }
                });
              }
            }
            _newItems.push({
              id: response.result[i].id,
              loadId: response.result[i].loadId,
              status: response.result[i].status,
              deleted: response.result[i].deleted,
              date: response.result[i].datePST,
              createdShortDatePST: response.result[i].createdShortDatePST,
              createdTimePST: response.result[i].createdTimePST,
              createdBy: response.result[i].createdBy,
              creatorName: response.result[i].creatorName,
              creatorInitials: response.result[i].creatorInitials,
              creatorAvatar: response.result[i].creatorAvatar,
              dispatcher: response.result[i].dispatcher,
              dispatcherName: response.result[i].dispatcherName,
              dispatcherInitials: response.result[i].dispatcherInitials,
              dispatcherAvatar: response.result[i].dispatcherAvatar,
              dispatcherEmail: response.result[i].dispatcherEmail,
              dispatcherPhone: response.result[i].dispatcherPhone,
              recipient: response.result[i].recipient,
              broker: response.result[i].broker,
              brokerName: response.result[i].brokerName,
              brokerEmail: response.result[i].brokerEmail,
              brokerPhone: response.result[i].brokerPhone,
              brokerManager: response.result[i].brokerManager,
              brokerManagerName: managerName,
              brokerManagerPhone: managerPhone,
              subject: response.result[i].subject,
              firstLocation: response.result[i].subject.indexOf('--') >= 0 ? response.result[i].subject.split('--')[0] : response.result[i].subject,
              lastLocation: response.result[i].subject.indexOf('--') >= 0 ? response.result[i].subject.split('--')[1] : '',
              totalDistance: this.$root.metersToMiles(response.result[i].totalDistance).toLocaleString() + ' ' + this.$root.t('milesShort'),
              distanceToPickup: this.$root.metersToMiles(response.result[i].distanceToPickup).toLocaleString() + ' ' + this.$root.t('milesShort'),
              totalDuration: this.$root.showDuration(response.result[i].totalDuration),
              ratePerMile: '$' + response.result[i].ratePerMile.toLocaleString(),
              totalRate: '$' + response.result[i].totalRate.toLocaleString(),
              mailsCount: response.result[i].mailsCount,
              isNewMails: response.result[i].isNewMails
            });
          }
          this.$set(this, 'items', _newItems);
          this.$set(this, 'itemsCount', response.count);

          isFilterDataUpdated = false;
          for (let f = 0; f < this.filtersItems.length; f++) {
            if (this.filtersItems[f].name === 'filterDispatcher') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.dispatchers);
                isFilterDataUpdated = true;
              }
            }

            if (this.filtersItems[f].name === 'filterTruck') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                this.$set(this.filtersItems[f], 'list', response.trucksList);
                isFilterDataUpdated = true;
              }
            }
          }
          if (isFilterDataUpdated) {
            this.isFilterInitialized = false;
            this.filtersKey = new Date().getTime();
          }

          this.$forceUpdate();
          this.loading = false;
          resolve({ items: this.items, total: this.itemsCount });
        }
      });
    }
  }
};
</script>
