<template>
  <v-container grid-list-md style="max-width:3840px;" v-if="$root.isUsersListAllowed(this.selectedRole)">
    <v-container fill-height grid-list-md text-xs-center v-if="loading">
      <v-skeleton-loader
        type="table"
        style="width:100%"
      ></v-skeleton-loader>
    </v-container>
    <div v-else-if="error">
      {{error}}
    </div>

    <SelectRole v-if="!hideFilters" :role="selectedRole" :showDriverOwnerOperator="true" @changed="onRoleChanged"/>

    <template v-if="selectedRole !== ''">
      <v-card v-if="!hideTitle" class="mt-1">
        <v-card-title>
          <h2 class="mr-2">{{$t('User.ListTitle')}}</h2>
          <v-btn v-if="$root.isUserCreateAllowed(selectedRole || '')"
                 small
                 color="success"
                 class="mr-2"
                 @click="$root.contextCreate">
            <v-icon left>mdi-plus</v-icon>{{$t('Create')}}
          </v-btn>
          <FiltersPanel
            v-if="!hideFilters"
            ref="filtersPanel"
            prefix="usersList"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @change="onFilterChanged"
          />
          <v-text-field
            class="ml-2"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined dense
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>

      <v-data-table v-if="!loading && !error && !$root.isMobile"
                    style="width:100%;"
                    :headers="headers"
                    :items="items"
                    :loading="itemsLoading"
                    :options.sync="options"
                    :items-per-page="itemsPerPage"
                    :footer-props="footer_props"
                    :show-select="!hideSelect"
                    :hide-default-footer="hidePagination"
                    item-key="name"
                    class="elevation-1"
                    dense
                    @click:row="onUserClick($event.id, $event.fullName)"
      >
        <template v-slot:top>
          <v-card flat v-if="!hidePagination">
            <!--<v-toolbar-title>{{ $t('LeftMenu.'+ (selectedRole ? selectedRole : 'AllUsersList')) }}</v-toolbar-title>-->
            <v-spacer></v-spacer>
            <Pagination v-if="itemsCount > options.itemsPerPage"
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="options.itemsPerPage"
                        @page-changed="pageChanged"></Pagination>
            <v-spacer></v-spacer>
          </v-card>
        </template>

        <template v-slot:footer="{ props: { options: { page }} }">
          <Pagination v-if="itemsCount > options.itemsPerPage && !hidePagination"
                      :key="'Pagination' + page"
                      :value="page"
                      :itemsCount="itemsCount"
                      :itemsPerPage="options.itemsPerPage"
                      @page-changed="pageChanged"></Pagination>
        </template>

        <template v-slot:item.createdBy="{ item }">
          <v-tooltip bottom v-if="item.createdBy">
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on"
                        @click.stop="$root.onShowUserPreview(item.createdBy, item.createdByName)"
                        style="cursor: pointer;"
                        class="ma-1"
                        :size="32"
                        :color="(item.createdByInitials && !item.createdByAvatar)?'#015aaa':null">
                <v-img :src="$root.getImageUrl('avatar', item.createdByAvatar)" v-if="item.createdByAvatar"></v-img>
                <span style="color:#fff;" v-else-if="item.createdByInitials">{{item.createdByInitials}}</span>
                <v-icon large v-else>account_circle</v-icon>
              </v-avatar>
            </template>
            <span>{{item.createdByName}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.avatar="{ item }">
          <template v-if="item.role === 'Broker'">
            <v-avatar :size="32" class="ma-1" :color="(item.fullName && !item.avatar)?'#015aaa':null">
              <v-img :src="$root.getImageUrl('avatar', item.avatar)" v-if="item.avatar"></v-img>
              <span class="white--text" v-else-if="item.fullName && item.avatarLetters">{{item.avatarLetters}}</span>
              <v-icon large v-else>account_circle</v-icon>
            </v-avatar>
          </template>
          <template v-else>
            <div class="text-center">
              <v-avatar :size="32" class="ma-1" :color="(item.fullName && !item.avatar)?'#015aaa':null">
                <v-img :src="$root.getImageUrl('avatar', item.avatar)" v-if="item.avatar"></v-img>
                <span class="white--text" v-else-if="item.fullName">{{item.avatarLetters}}</span>
                <v-icon large v-else>account_circle</v-icon>
              </v-avatar>
              <br/>
              <v-chip :small="!small" :x-small="small" :color="item.statusIsLoggedIn === 0 ? 'error' : (item.statusIsAway === 1 ? 'warning' : 'success')">
                {{item.statusIsLoggedIn === 0 ? $root.t('IsOffline') : (item.statusIsAway === 1 ? $root.t('IsAway') : $root.t('IsActive'))}}
              </v-chip>
            </div>
          </template>
        </template>
        <template v-slot:item.email="{ item }">
          <template v-if="item.email.indexOf(',') > 0">
            <template v-for="email_val in item.email.split(',')">
            <a :href="'mailto:'+email_val" target="_blank" @click.stop="">{{email_val}}</a>
            <v-btn v-if="email_val" small icon @click.stop="$root.copyToClipboard(email_val)"><v-icon small>mdi-content-copy</v-icon></v-btn>&nbsp;
            </template>
          </template>
          <template v-else>
            <a :href="'mailto:'+item.email" target="_blank" @click.stop="">{{item.email}}</a>
            <v-btn v-if="item.email" small icon @click.stop="$root.copyToClipboard(item.email)"><v-icon small>mdi-content-copy</v-icon></v-btn>
          </template>
        </template>
        <template v-slot:item.rate="{ item }">
          <template v-if="item.driverCurrentSoloRate || item.driverCurrentTeamRate">
            <span v-if="item.driverCurrentSoloRate > 0">${{parseFloat(item.driverCurrentSoloRate).toFixed(2)}}</span>
            <span v-else-if="item.driverRates && item.driverRates.length > 0">${{item.defaultMiles && item.defaultMiles.hasOwnProperty('soloRate') ? item.defaultMiles.soloRate : 'N/A'}}</span>
            /
            <span v-if="item.driverCurrentTeamRate > 0">${{parseFloat(item.driverCurrentTeamRate).toFixed(2)}}</span>
            <span v-else-if="item.driverRates && item.driverRates.length > 0">${{item.defaultMiles && item.defaultMiles.hasOwnProperty('teamRate') ? item.defaultMiles.teamRate : 'N/A'}}</span>
          </template>
          <v-list-item-subtitle v-if="item.driverCurrentRateExpiredDATE">
            <b>{{$t('Expires')}}</b>: {{$root.formatDate(item.driverCurrentRateExpiredDATE)}}
          </v-list-item-subtitle>
          <!--<v-layout class="pt-2">
            <span class="text-button">Solo:</span>
            <v-menu offset-y v-if="isDriverRateEditingAllowed">
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  ${{parseFloat(item.driverCurrentSoloRate).toFixed(2)}}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(rateItem, index) in item.driverRates">
                  <v-list-item :key="index" @click="$root.setCurrentSoloRate(rateItem.soloRate, item.id);item.driverCurrentSoloRate=rateItem.soloRate;$forceUpdate();">
                    <v-list-item-title>{{$t('SetAsCurrent', {n: rateItem.name+ ' $'+ rateItem.soloRate })}}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <span class="text-button" v-else>N/A</span>
          </v-layout>
          <v-layout>
            <span class="text-button">Team:</span>
            <v-menu offset-y v-if="isDriverRateEditingAllowed">
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  ${{parseFloat(item.driverCurrentTeamRate).toFixed(2)}}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(rateItem, index) in item.driverRates">
                  <v-list-item :key="index" @click="$root.setCurrentTeamRate(rateItem.teamRate, item.id);item.driverCurrentTeamRate=rateItem.teamRate;$forceUpdate();">
                    <v-list-item-title>Set {{rateItem.type}}: ${{rateItem.teamRate}} as current</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <span class="text-button" v-else>N/A</span>
          </v-layout>-->
        </template>
        <template v-slot:item.truck="{ item }">
          <template>
            <v-layout @click.stop="">
              <v-flex xs4 class="pt-2" v-if="item.truck.id">
                <v-avatar :size="32" class="ma-1" tile style="cursor: pointer;" @click.stop="$root.route('/trucks/page/' + item.truck.id)">
                  <v-img v-if="item.truck.avatar" :src="$root.getTruckFirstUrl(item.truck.avatar)"></v-img>
                  <v-icon v-else>fa-truck</v-icon>
                </v-avatar>
              </v-flex>
              <v-flex xs2 class="pt-2" v-else>
                <v-icon>fa-truck</v-icon>
              </v-flex>
              <v-flex xs10>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn text color="primary" dark v-on="on" v-if="item.truck.name">
                      {{item.truck.name}}
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                    <v-btn text v-on="on" v-else>
                      {{$t('User.SelectTruck')}}
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(truck, index) in trucks">
                      <v-list-item :key="index" @click="setDriverTruck(truck, item)">
                        <v-list-item-title>{{ truck.name }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <!--<a href="#" @click.stop="$root.route('/trucks/page/' + item.truck.id)">{{item.truck.name}}!!</a>-->
                <br/>
                <small v-if="item.truckRoleDriver">{{$t('User.Driver')}}</small>
                <small v-if="item.truckRoleCoDriver">{{$t('User.CoDriver')}}</small>
                <small>{{$root.getTruckStatusName(item.truck.status)}}</small>
              </v-flex>
            </v-layout>
          </template>
        </template>
        <template v-slot:item.activity="{ item }">
          <!--<DriverActivityChart :driverId="item.id" height="50" width="200"></DriverActivityChart>-->
        </template>
        <template v-slot:item.availableTimeBreak="{ item }">
          <v-chip color="purple lighten-2" v-if="item.keeptruckinid && item.role === 'Driver'" class="white--text">
            <v-avatar left>
            <v-progress-circular size="20"
                                 :indeterminate="false"
                                 :rotate="-90"
                                 :value="(item.availableTimeBreak / maxBreakTime * 100)+'%'"></v-progress-circular>
            </v-avatar>
          {{$root.showDuration(item.availableTimeBreak)}}
          </v-chip>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip v-if="item.status && item.status.length > 0" :color="$root.getDutyColor(item.status)" class="white--text">{{$t('KeepTruckin.'+item.status)}}</v-chip>
          <v-chip v-else class="white--text">N/A</v-chip>
        </template>
        <template v-slot:item.availableTimeDrive="{ item }">
          <v-chip color="green" v-if="item.keeptruckinid" class="white--text">
            <v-avatar left>
              <v-progress-circular size="20"
                                   :indeterminate="false"
                                   :rotate="-90"
                                   :value="(item.availableTimeDrive / maxDriveTime * 100)+'%'"></v-progress-circular>
            </v-avatar>
            {{$root.showDuration(item.availableTimeDrive)}}
          </v-chip>
        </template>
        <template v-slot:item.availableTimeShift="{ item }">
          <v-chip color="light-blue" v-if="item.keeptruckinid" class="white--text">
            <v-avatar left>
              <v-progress-circular size="20"
                                   :indeterminate="false"
                                   :rotate="-90"
                                   :value="(item.availableTimeShift / maxShiftTime * 100)+'%'"></v-progress-circular>
            </v-avatar>
          {{$root.showDuration(item.availableTimeShift)}}
          </v-chip>
        </template>
        <template v-slot:item.fullName="{ item }">
          {{item.fullName}} <v-chip dense small class="error" v-if="parseInt(item.statusIsActive) === 0">{{$t('Deactivated')}}</v-chip><v-chip dense small class="info" v-if="parseInt(item.statusIsActive) === -1">{{$t('NewRegistration')}}</v-chip>
        </template>
        <template v-slot:item.availableTimeCycle="{ item }">
          <v-chip color="red" v-if="item.keeptruckinid" class="white--text">
            <v-avatar left>
              <v-progress-circular size="20"
                                   :indeterminate="false"
                                   :rotate="-90"
                                   :value="(item.availableTimeCycle / maxCycleTime * 100)+'%'"></v-progress-circular>
            </v-avatar>
          {{$root.showDuration(item.availableTimeCycle)}}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }" v-if="$root.isOnline">
          <div v-if="$root.isUsersListEditAllowed(selectedRole)"  @click.stop="">

            <v-tooltip bottom v-if="!$cookies.get('ogt') && $store.getters.isUserRoleSuperAdmin && item.role !== 'Broker' && item.id !== $store.getters.currentUser.id">
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mr-1">
                  <v-icon small @click.stop="$root.setViewAs(item.id, item.fullName)">fa-user-secret</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersListViewAs')}}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.keeptruckinid && item.role === $store.getters.roleDriver">
              <template v-slot:activator="{ on }">
                <v-btn icon small @click.stop="onShowHOSDialog(item)" v-on="on">
                  <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersHOS')}}</span>
            </v-tooltip>

            <!--<v-tooltip bottom v-if="!$cookies.get('ogt')">
              <template v-slot:activator="{ on }">
                <v-btn text icon small v-on="on">
                  <v-icon small @click.stop="$root.route($store.getters.currentUser.id === item.id ? '/user/page' : '/user/page/' + item.id)" class="mr-1">fa-eye</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersListUserPage')}}</span>
            </v-tooltip>
            -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mr-1">
                  <v-icon small v-if="item.id === $store.getters.currentUser.id" @click.stop="$router.push('/user/settings')">fa-cog</v-icon>
                  <v-icon small v-else @click.stop="onUserEditClick(item.id, item.fullName)">fa-user-edit</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersListEdit')}}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.role !== 'SuperAdministrator' &&  item.id !== $store.getters.currentUser.id">
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" :color="item.statusIsActive === 1 ? 'red' : 'success' ">
                  <v-icon small
                          @click.stop="activeToggle(item.fullName, item.statusIsActive === 1 ? true : false, item.id)"
                          v-html="parseInt(item.statusIsActive) === 1 ? 'mdi-delete' : 'mdi-restore'" >fa-user-slash</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersListDeactivate')}}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.role === 'Broker'">
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" class="mr-1">
                  <v-icon small @click.stop="onMergeBrokerBtn(item.id)">mdi-merge</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Tooltips.UsersMergeBroker')}}</span>
            </v-tooltip>

          </div>
        </template>

      </v-data-table>
      <!-- MOBILE LIST -->

      <v-list three-line v-if="!loading && !error && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
        <!--<v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <v-pagination v-model="page"
                          @input="pageChanged"
                          :length="Math.ceil(itemsCount/itemsPerPage)"
                          total-visible="5"></v-pagination>
          </v-list-item-content>
        </v-list-item>-->
        <template v-for="(item,index) in items">
          <v-list-item
            :key="'user' + item.id"
            @click="onUserClick(item.id, item.fullName)"
          >
            <v-list-item-icon>
              <template v-if="item.role === 'Broker'">
                <v-avatar :size="32" class="ma-1" :color="(item.fullName && !item.avatar)?'#015aaa':null">
                  <v-img :src="$root.getImageUrl('avatar', item.avatar)" v-if="item.avatar"></v-img>
                  <span class="white--text" v-else-if="item.fullName && item.avatarLetters">{{item.avatarLetters}}</span>
                  <v-icon large v-else>account_circle</v-icon>
                </v-avatar>
              </template>
              <template v-else>
                <div class="text-center">
                  <v-avatar :size="32" class="ma-1" :color="(item.fullName && !item.avatar)?'#015aaa':null">
                    <v-img :src="$root.getImageUrl('avatar', item.avatar)" v-if="item.avatar"></v-img>
                    <span class="white--text" v-else-if="item.fullName">{{item.avatarLetters}}</span>
                    <v-icon large v-else>account_circle</v-icon>
                  </v-avatar>
                  <br/>
                  <v-chip small :color="item.statusIsLoggedIn === 0 ? 'error' : (item.statusIsAway === 1 ? 'warning' : 'success')">
                    {{item.statusIsLoggedIn === 0 ? $root.t('IsOffline') : (item.statusIsAway === 1 ? $root.t('IsAway') : $root.t('IsActive'))}}
                  </v-chip>
                </div>
              </template>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{item.fullName}}</v-list-item-title>
              <v-list-item-subtitle>{{item.role}}</v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-layout>
                  <v-flex>
                    <v-btn small icon v-if="!$cookies.get('ogt') && item.role !== 'Broker' && item.id !== $store.getters.currentUser.id">
                      <v-icon small @click.stop="$root.setViewAs(item.id, item.fullName)" class="mt-2">fa-user-secret</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex v-if="item.keeptruckinid && item.role === $store.getters.roleDriver">
                    <v-btn icon small @click.stop="onShowHOSDialog(item)">
                      <v-icon small class="mt-2">mdi-clock-outline</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn icon>
                      <v-icon v-if="item.id === $store.getters.currentUser.id" @click.stop="$router.push('/user/settings')" small>fa-cog</v-icon>
                      <v-icon v-else small @click.stop="onUserEditClick(item.id, item.fullName)">fa-user-edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <v-btn small icon v-if="item.role === 'Broker'">
                      <v-icon small @click.stop="onMergeBrokerBtn(item.id)" class="mt-2">mdi-merge</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!--<v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <v-pagination v-model="page"
                          @input="pageChanged"
                          :length="Math.ceil(itemsCount/itemsPerPage)"
                          total-visible="5"></v-pagination>
          </v-list-item-content>
        </v-list-item>-->
      </v-list>
    </template>

    <v-dialog v-model="mergeBrokerDialog" width="500">
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-layout>
                  <h2>{{$t('User.MergeBrokers')}}</h2><v-spacer></v-spacer><v-btn icon @click="mergeBrokerDialog=false;"><v-icon>mdi-close</v-icon></v-btn>
                </v-layout>
                <SelectUser
                  ref="brokerSelect"
                  :key="'from'+mergeFromBorkerId"
                  :label="$t('User.SelectFromBroker')"
                  role="Broker"
                  :value="mergeFromBorkerId"
                  @change="(value) => { mergeFromBorkerId = value;}">
                </SelectUser>
                <SelectUser
                  ref="brokerSelect"
                  :key="'to'+mergeToBorkerId"
                  :label="$t('User.SelectToBroker')"
                  role="Broker"
                  :value="mergeToBorkerId"
                  @change="(value) => { mergeToBorkerId = value;}">
                </SelectUser>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn color="primary" width="100%" :loading="mergeBrokerDialogLoading" v-if="mergeFromBorkerId > 0 && mergeToBorkerId > 0" @click="mergeBrokers(mergeFromBorkerId, mergeToBorkerId)">
            {{$t('User.MergeBrokers')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHOS">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer><v-btn icon @click="dialogHOS=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <DriverHOSCard v-if="itemHOS.keeptruckinid" :userId="itemHOS.id" :showActivityChart="true" :showHOSData="true" :key="'DriverHOSCard' + itemHOS.id"></DriverHOSCard>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import userFunctionsMixin from './../../mixins/userFunctionsMixin';
import DriverHOSCard from './../../components/DriverHOSCard.vue';
import SelectUser from './../../components/SelectUser.vue';
import Pagination from '../../components/Pagination';
import SelectRole from '../../components/SelectRole';
import FiltersPanel from '../../components/FiltersPanel';

export default {
  props: ['role', 'responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideSelect', 'hideReportMode', 'hideTotals'],
  components: { FiltersPanel, SelectRole, DriverHOSCard, SelectUser, Pagination },
  mixins: [userFunctionsMixin],
  data () {
    return {
      polling: null,
      loading: true,
      error: '',
      page: 1,
      itemsPerPage: 25,
      itemsLoading: false,
      options: {},
      headers: [],
      small: false,
      itemsCount: 0,
      items: [],
      statusFilter: 1,
      trucks: [],
      maxDriveTime: 11 * 3600,
      maxBreakTime: 8 * 3600,
      maxShiftTime: 14 * 3600,
      maxCycleTime: 70 * 3600,
      mergeBrokerDialog: false,
      mergeBrokerDialogLoading: false,
      mergeFromBorkerId: 0,
      mergeToBorkerId: 0,
      footer_props: {
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': '',
        'disable-items-per-page': false
      },
      isDriverRateEditingAllowed: false,
      dialogHOS: false,
      itemHOS: {},
      selectedRole: '',
      search: '',
      isFilterInitialized: false,
      filtersKey: new Date().getTime(),
      filtersItems: [
        {
          name: 'statusFilter',
          label: 'Status',
          field: 1,
          list: [
            { id: 0, name: 'Inactive' },
            { id: 1, name: 'Active' },
            { id: -1, name: 'Pending' }
          ]
        }
      ]
    };
  },
  mounted () {
    this.small = this.$root.isMobile;
    if (this.$route.params.role) {
      this.selectedRole = this.$route.params.role;
    } else if (this.$root.getLocalStorageItem('usersListSelectedRole')) {
      let roleItem = this.$root.getLocalStorageItem('usersListSelectedRole');
      if (this.$root.isUsersListAllowed(roleItem)) {
        this.selectedRole = roleItem;
      }
    }
    this.$root.setLastListPath(this.$route.path);
    this.$nextTick(() => {
      this.setHeaders();

      this.$root.contextCreate = () => {
        if (this.selectedRole) {
          this.$root.route('/user/create/' + (this.selectedRole === 'DriverOwnerOperator' ? 'Driver' : this.selectedRole));
        } else {
          this.$root.route('/user/create');
        }
      };
      this.$root.getAccessRightsByField('settings', 'driverSwitchToFullUI').then((response) => {
        if (response.status === 'ok') {
          const rights = response.rights;
          let _r = this.$root.getAccessRightsArrayFromNumber(rights);
          if (_r[0] === '0' && _r[1] === '1' && _r[2] === '1' && _r[3] === '1') {
            this.isDriverRateEditingAllowed = true;
          }
        }
      });

      this.fetchData();
      this.loading = false;
      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData(false);
      }, this.$store.getters.pullIntervalList);
    });
  },
  beforeDestroy () {
    this.$root.contextCreate = () => {};
    clearInterval(this.polling);
  },
  watch: {
    search () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1;
          this.page = 1;
          this.fetchData();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    /* options: {
          handler () {
              let { page, itemsPerPage } = this.options;
              if (itemsPerPage) {
                  this.$root.setLocalStorageItem("usersItemsPerPage", itemsPerPage);
              }
              this.fetchData().then(data => {
                  this.items = data.items;
                  this.itemsCount = data.total;
              });
          },
          deep: true
      } */
  },
  computed: {
  },
  methods: {

    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.options.page = 1;
        this.page = 1;
      }
      this.fetchData();
      this.isFilterInitialized = true;
    },
    onUserClick (id, userName) {
      if (this.$store.getters.currentUser.id === id) {
        this.$root.route('/user/page');
      } else {
        //this.$root.onShowUserPreview(id, userName);
        this.$root.route('/user/page/' + id);
      }
    },
    onUserEditClick (id, userName) {
      if (this.$store.getters.currentUser.id === id) {
        this.$root.route('/user/settings');
      } else {
        //this.$root.onShowUserPreview(id, userName);
        this.$root.route('/user/edit/' + id);
      }
    },
    setHeaders () {
      this.headers = [
        { text: '', value: 'avatar', divider: false, sortable: false },
        { text: 'Full Name', value: 'fullName', sortable: false },
        { text: 'Role', value: 'roleDisplay' },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'CreatedBy', value: 'createdBy', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ];

      if (this.selectedRole && this.selectedRole === 'Driver') {
        this.headers = [
          { text: '', value: 'avatar', divider: false, sortable: false },
          { text: 'Full Name', value: 'fullName', sortable: false },
          { text: 'Status', value: 'status' },
          { text: 'Role', value: 'roleDisplay' },
          { text: 'Email', value: 'email', sortable: false },
          { text: 'Current Rate Solo/Team', value: 'rate', sortable: false },
          { text: 'Truck', value: 'truck', sortable: false },
          { text: 'Activity', value: 'activity', sortable: false },
          { text: this.$root.t('KeepTruckin.break'), value: 'availableTimeBreak', sortable: false },
          { text: this.$root.t('KeepTruckin.drive'), value: 'availableTimeDrive', sortable: false },
          { text: this.$root.t('KeepTruckin.shift'), value: 'availableTimeShift', sortable: false },
          { text: this.$root.t('KeepTruckin.cycle'), value: 'availableTimeCycle', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false }
        ];
      }
    },
    onRoleChanged (value) {
      this.$root.setLocalStorageItem('usersListSelectedRole', value);
      this.selectedRole = value;
      this.setHeaders();
      this.fetchData(true);
    },
    onShowHOSDialog (item) {
      this.itemHOS = item;
      this.dialogHOS = true;
    },
    pageChanged (event) {
      this.options.page = event;
    },
    onMergeBrokerBtn (fromBrokerId) {
      this.mergeFromBorkerId = fromBrokerId;
      this.mergeBrokerDialog = true;
    },
    mergeBrokers (fromBrokerId, toBrokerId) {
      this.$dialog.confirm(this.$root.t('User.MergingBrokersConfirmation'), {
        okText: this.$root.t('User.MergeBrokers'),
        cancelText: this.$root.t('Cancel')
      }).then(dialog => {
        this.mergeBrokerDialogLoading = true;
        this.$root.mergeBrokers(fromBrokerId, toBrokerId).then((response) => {
          this.mergeBrokerDialogLoading = false;
          this.mergeBrokerDialog = false;
          this.fetchData();
        }).catch((err) => {
          this.mergeBrokerDialogLoading = false;
        });
      });
    },
    setDriverTruck (truck, user) {
      this.$dialog.confirm(this.$root.t('User.TruckChangeDriverTypeConfirmationMsg'), {
        okText: this.$root.t('User.SetAsCoDriver'),
        cancelText: this.$root.t('User.SetAsDriver')
      }).then(dialog => {
        user.truckRoleDriver = null;
        user.truckRoleCoDriver = user.id;
        this.$root.updateUserFields({ driverTruck: truck.id }, user.id);
        this.$root.saveTruck({ codriver: user.id }, truck.id);
      }).catch(() => {
        user.truckRoleCoDriver = null;
        user.truckRoleDriver = user.id;
        this.$root.updateUserFields({ driverTruck: truck.id }, user.id);
        this.$root.saveTruck({ driver: user.id }, truck.id);
      });

      user.truck = {
        id: truck.id,
        avatar: truck.avatar,
        name: truck.name,
        status: truck.status
      };
    },
    fetchData (showLoading = true) {
      // Load truck list
      this.$root.getTrucksList().then((response) => {
        let i;
        if (response.status === 'ok') {
          this.trucks = [];
          for (i = 0; i < response.result.length; i++) {
            this.trucks.push({
              id: response.result[i].id,
              avatar: response.result[i].truckAvatarUPLOAD,
              name: response.result[i].name,
              status: response.result[i].status,
              driver: response.result[i].driver,
              codriver: response.result[i].codriver
            });
          }
        }
      });

      return new Promise(async (resolve, reject) => {
        if (showLoading) this.itemsLoading = true;
        let { page, itemsPerPage } = this.options;
        this.error = null;
        let _newItems = [];

        let itemsPP = itemsPerPage < 0 ? 0 : itemsPerPage;
        let orderField = (this.options.sortBy && this.options.sortBy[0]) ? this.options.sortBy[0] : null;
        let orderDesc = (this.options.sortDesc && this.options.sortDesc[0]) ? this.options.sortDesc[0] : null;

        let response = {};
        if (this.responseOverride) {
          response = this.responseOverride;
        }
        else {
          response = await this.$root.loadUsersList(this.selectedRole, false, false, this.statusFilter, this.search);
        }

        if (response.status && response.status === 'error') {
          console.error('ERROR', response.msg);
          resolve(response);
          if (showLoading) this.itemsLoading = false;
          this.loading = false;
        }
        else if (response.status === 'ok') {
          response = response.result;
          for (let i = 0; i < response.data.length; i++) {
            // Do not show brokers in all users list
            if (!this.selectedRole && response.data[i].role === 'Broker') continue;
            let status = '';
            let availableTimeBreak = null;
            let availableTimeDrive = null;
            let availableTimeShift = null;
            let availableTimeCycle = null;
            if (response.data[i].role === 'Driver' &&
                              response.data[i].keeptruckinid !== 0 &&
                              response.data[i].keeptruckindata) {
              const keeptruckindata = JSON.parse(response.data[i].keeptruckindata);
              if (keeptruckindata) {
                if (keeptruckindata.duty_status) {
                  status = keeptruckindata.duty_status;
                }
                if (keeptruckindata.available_time) {
                  availableTimeBreak = keeptruckindata.available_time.break;
                  availableTimeDrive = keeptruckindata.available_time.drive;
                  availableTimeShift = keeptruckindata.available_time.shift;
                  availableTimeCycle = keeptruckindata.available_time.cycle;
                }
              }
            }
            _newItems.push({
              id: response.data[i].id,
              status: status,
              avatar: response.data[i].avatarUPLOAD,
              email: response.data[i].email,
              role: response.data[i].role,
              roleDisplay: this.$root.t('Roles.' + response.data[i].role),
              fullName: response.data[i].firstName + ' ' + (response.data[i].lastName ? response.data[i].lastName : ''),
              avatarLetters: (response.data[i].firstName && response.data[i].lastName) ? response.data[i].firstName.charAt(0) + response.data[i].lastName.charAt(0) : '',
              statusIsActive: response.data[i].statusIsActive,
              statusIsLoggedIn: response.data[i].statusIsLoggedIn,
              sinceLastActivitySec: response.data[i].sinceLastActivitySec,
              keeptruckinid: response.data[i].keeptruckinid,
              truck: { id: response.data[i].truckId, avatar: response.data[i].truckAvatar, name: response.data[i].truckName, status: response.data[i].truckStatus },
              truckRoleDriver: response.data[i].truckDriver,
              truckRoleCoDriver: response.data[i].truckCoDriver,
              driverCurrentSoloRate: response.data[i].driverCurrentSoloRate,
              driverCurrentTeamRate: response.data[i].driverCurrentTeamRate,
              driverRates: response.data[i].driverRates,
              defaultMiles: response.data[i].hasOwnProperty('driverRates') && response.data[i].driverRates ? response.data[i].driverRates.find(el => el.name === 'Default Miles') : null,
              availableTimeBreak: availableTimeBreak,
              availableTimeDrive: availableTimeDrive,
              availableTimeShift: availableTimeShift,
              availableTimeCycle: availableTimeCycle,
              createdBy: response.data[i].createdBy,
              createdByName: response.data[i].createdByName,
              createdByInitials: response.data[i].createdByInitials,
              createdByAvatar: response.data[i].createdByAvatar,
              driverCurrentRateExpiredDATE: response.data[i].driverCurrentRateExpiredDATE
            });
          }
          this.$set(this, 'items', _newItems);
          // this.$set(this, 'itemsCount', response.count);
          this.$set(this, 'itemsCount', _newItems.length);
          this.$forceUpdate();
          if (showLoading) this.itemsLoading = false;
          this.loading = false;
          resolve({ items: this.items, total: this.itemsCount });
        }
      });
    }
  }
};
</script>
