<template>
  <div>
    <!--<v-overlay :value="isLoading" :absolute="false">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>-->
    <v-text-field
      v-model="search"
      label="Search string"
      class="ma-3"
      :loading="isLoading"
      v-on:keyup.enter="doSearch(search)"
      outlined dense>
      <template v-slot:prepend-inner>
        <v-icon large>mdi-magnify</v-icon>
      </template>
      <template v-slot:append>
        <v-btn @click="doSearch(search)" small color="primary" :loading="isLoading">{{$t('Search')}}</v-btn>
      </template>
    </v-text-field>

    <v-tabs v-model="panel" show-arrows grow active-class="tabs-active-color">
      <v-tab key="loadsSearch">
        {{$t('LeftMenu.Loads')}} <v-badge v-if="loadsResponse && loadsResponse.hasOwnProperty('result')" :content="loadsResponse.result.length || '0'" inline/>
      </v-tab>
      <v-tab key="invoicesSearch">
        {{$t('LeftMenu.Invoices')}} <v-badge v-if="invoicesResponse && invoicesResponse.hasOwnProperty('result')" :content="invoicesResponse.result.length || '0'" inline/>
      </v-tab>
      <v-tab key="bidsSearch">
        {{$t('LeftMenu.Bids')}} <v-badge v-if="bidsResponse && bidsResponse.hasOwnProperty('result')" :content="bidsResponse.result.length || '0'" inline/>
      </v-tab>
      <v-tab key="trucksSearch">
        {{$t('LeftMenu.Trucks')}} <v-badge v-if="trucksResponse && trucksResponse.hasOwnProperty('result')" :content="trucksResponse.result.length || '0'" inline/>
      </v-tab>
      <v-tab key="trailersSearch">
        {{$t('LeftMenu.Trailers')}} <v-badge v-if="trailersResponse && trailersResponse.hasOwnProperty('result')" :content="trailersResponse.result.length || '0'" inline/>
      </v-tab>
      <v-tab key="usersSearch">
        {{$t('LeftMenu.Users')}} <v-badge v-if="usersResponse && usersResponse.hasOwnProperty('result') && usersResponse.result.hasOwnProperty('data')" :content="usersResponse.result.data.length || '0'" inline/>
      </v-tab>
      <v-tab key="documentsSearch">
        {{$t('LeftMenu.Documents')}} <v-badge v-if="documentsResponse && documentsResponse.hasOwnProperty('result')" :content="documentsResponse.result.length || '0'" inline/>
      </v-tab>
    </v-tabs>
    <v-divider/>
    <v-tabs-items v-model="panel">
      <v-tab-item key="loadsSearch">
        <h1 class="text-center" v-if="!loadsResponse || !loadsResponse.hasOwnProperty('result') || loadsResponse.result.length === 0">{{ $t('NoResults') }}</h1>
        <LoadsList v-else
                   :key="searchKey"
                   :responseOverride="loadsResponse"
                   :hideFilters="true"
                   :hideTitle="true"
                   :hidePagination="true"
                   :hideNextActions="true"
                   :hideNotEndedLoad="true"
        />
      </v-tab-item>
      <v-tab-item key="invoicesSearch">
        <h1 class="text-center" v-if="!invoicesResponse || !invoicesResponse.hasOwnProperty('result') || invoicesResponse.result.length === 0">{{ $t('NoResults') }}</h1>
        <InvoicesList v-else
                      :key="searchKey"
                      :responseOverride="invoicesResponse"
                      :hideFilters="true"
                      :hideTitle="true"
                      :hidePagination="true"
                      :hideSelect="true"
                      :hideReportMode="true"
                      :hideTotals="true"
        />
      </v-tab-item>
      <v-tab-item key="bidsSearch">
        <h1 class="text-center" v-if="!bidsResponse || !bidsResponse.hasOwnProperty('result') || bidsResponse.result.length === 0">{{ $t('NoResults') }}</h1>
        <BidsList v-else
                  :key="searchKey"
                  :responseOverride="bidsResponse"
                  :hideFilters="true"
                  :hideTitle="true"
                  :hidePagination="true"
                  :hideSelect="true"
                  :hideReportMode="true"
                  :hideTotals="true"/>
      </v-tab-item>

      <v-tab-item key="trucksSearch">
        <h1 class="text-center" v-if="!trucksResponse || !trucksResponse.hasOwnProperty('result') || trucksResponse.result.length === 0" class="text-center">{{ $t('NoResults') }}</h1>
        <TrucksList v-else
                  :key="searchKey"
                  :responseOverride="trucksResponse"
                  :hideFilters="true"
                  :hideTitle="true"
                  :hidePagination="true"
                  :hideSelect="true"
                  :hideReportMode="true"
                  :hideTotals="true"
                  :showHiddenTrucks="true"/>
      </v-tab-item>

      <v-tab-item key="trailersSearch">
        <h1 class="text-center" v-if="!trailersResponse || !trailersResponse.hasOwnProperty('result') || trailersResponse.result.length === 0">{{ $t('NoResults') }}</h1>
        <TrucksList v-else
                  :key="searchKey"
                  :responseOverride="trailersResponse"
                  :hideFilters="true"
                  :hideTitle="true"
                  :hidePagination="true"
                  :hideSelect="true"
                  :hideReportMode="true"
                  :hideTotals="true"
                  :showHiddenTrucks="true"/>
      </v-tab-item>

      <v-tab-item key="usersSearch">
        <h1 class="text-center" v-if="!usersResponse || !usersResponse.hasOwnProperty('result') || usersResponse.result.data.length === 0">{{ $t('NoResults') }}</h1>
        <UsersList v-else
                  :key="searchKey"
                  :responseOverride="usersResponse"
                  :hideFilters="true"
                  :hideTitle="true"
                  :hidePagination="true"
                  :hideSelect="true"
                  :hideReportMode="true"
                  :hideTotals="true"/>
      </v-tab-item>

      <v-tab-item key="documentsSearch">
        <h1 class="text-center" v-if="!documentsResponse || !documentsResponse.hasOwnProperty('result') || documentsResponse.result.length === 0">{{ $t('NoResults') }}</h1>
        <DocumentsList v-else
                  :key="searchKey"
                  :responseOverride="documentsResponse"
                  :hideFilters="true"
                  :hideTitle="true"
                  :hidePagination="true"
                  :hideSelect="true"
                  :hideReportMode="true"
                  :hideTotals="true"/>
      </v-tab-item>

    </v-tabs-items>

    <!--<v-dialog v-model="bidInformationDialog" width="500">
      <BidInformation :bidId="bidInformationId" :key="'bid_dlg' + bidInformationId"></BidInformation>
    </v-dialog>

    <v-dialog v-model="loadInformationDialog" width="500">
      <LoadInformation :loadId="loadInformationId" :key="'load_dlg' + loadInformationId"></LoadInformation>
    </v-dialog>-->

  </div>
</template>

<script>
import BidInformation from './BidInformation.vue';
import LoadInformation from './LoadInformation.vue';
import LoadsList from './../views/Loads/List.vue';
import InvoicesList from './../views/Invoices/List.vue';
import BidsList from './../views/Bids/List.vue';
import UsersList from './../views/User/List.vue';
import TrucksList from './../views/Trucks/List.vue';
import DocumentsList from './../views/Documents/List.vue';

export default {
  props: [],
  components: { BidInformation, LoadInformation, LoadsList, InvoicesList, BidsList, UsersList, TrucksList, DocumentsList },
  data: () => ({
    isLoading: false,
    search: '',
    searchKey: new Date().getTime(),
    panel: 'loadsSearch',
    loadsResponse: {},
    invoicesResponse: {},
    bidsResponse: {},
    usersResponse: {},
    trucksResponse: {},
    trailersResponse: {},
    documentsResponse: {},
    typeTimer: null
  }),
  computed: {
  },
  watch: {
    panel (val) {
      console.log(val === 1 ? 'loads' : 'inv');
    }
    /* search (val) {
      clearTimeout(this.typeTimer);
      this.typeTimer = setTimeout(() => {
        if (val.length < 1) return;
        this.doSearch(val);
      }, 500);
    } */
  },
  mounted () {
    if (this.$root.searchQuery.length > 0) {
      this.search = this.$root.searchQuery;
      this.doSearch(this.search);
    }
  },
  methods: {
    async doSearch (val) {
      let response = null;
      if (val.length < 1) return;
      this.isLoading = true;

      response = await this.$root.getLoadList(0, 0, 50, 0, 0, 0, 0, -1, true, 'all', 0, val);
      this.$set(this, 'loadsResponse', response);

      response = await this.$root.getInvoicesList(0, 'All', 0, 0, 0, 0, 50, 0, 0, 0, 0, 0, 0, 0, val);
      this.$set(this, 'invoicesResponse', response);

      response = await this.$root.getBidsList(0, 0, 50, 0, 0, 0, -1, 0, val);
      this.$set(this, 'bidsResponse', response);

      response = await this.$root.loadUsersList(null, false, false, 1, val);
      this.$set(this, 'usersResponse', response);

      response = await this.$root.getTrucksList(true, 0, 0, val);
      this.$set(this, 'trucksResponse', response);

      response = await this.$root.getTrucksList(true, 0, 1, val);
      this.$set(this, 'trailersResponse', response);

      response = await this.$root.getDocumentsList(0, 50, 0, 'All', val);
      this.$set(this, 'documentsResponse', response);

      this.$set(this, 'searchKey', new Date().getTime());

      /*this.$root.searchByAll(val).then((response) => {
        if (response.status === 'ok') {
          if (response.hasOwnProperty('invoicesItems')) {
            this.invoicesItems = response.invoicesItems;
          }
          if (response.hasOwnProperty('bidsItems')) {
            this.bidsItems = response.bidsItems;
          }
          if (response.hasOwnProperty('usersItems')) {
            this.usersItems = response.usersItems;
          }
        }
        this.isLoading = false;
      });*/
      this.isLoading = false;
    },
    onShowLoadInformation (id) {
      this.loadInformationId = id;
      this.loadInformationDialog = true;
    },
    onShowBidInformation (id) {
      this.bidInformationId = id;
      this.bidInformationDialog = true;
    },
    onResultRowClick (path) {
      this.$root.searchDialog = false;
      this.$root.route(path);
    }
  }
};
</script>
