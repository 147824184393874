<template>
  <v-container grid-list-md>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="error !== ''">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-layout wrap v-if="!hideFilters">
        <v-flex xs12 md3 v-if="isTrucksCreateAllowed" class="mt-5">
          <v-btn @click="$root.route('/trucks/create' + ($route.params.isTrailer ? '/1' : ''))" color="success" small><v-icon left>mdi-plus</v-icon>{{$route.params.isTrailer ? $t('Trucks.CreateTrailer') : $t('Trucks.Create')}}</v-btn>
        </v-flex>
        <v-flex xs6 md3>
          <v-switch
            data-cy="trucksShowImagesSwitch"
            class="ml-5"
            v-model="showTrucksImages"
            inset
            :label="$route.params.isTrailer ? $t('Trucks.ShowTrailersImages') : $t('Trucks.ShowTrucksImages')"></v-switch>
        </v-flex>
        <v-flex xs6 md3>
          <v-switch
            data-cy="trucksShowHiddenSwitch"
            class="ml-5"
            @change="onShowHiddenTrucksSwitchChange"
            v-model="showHiddenTrucks"
            inset
            :label="$route.params.isTrailer ? $t('Trucks.ShowHiddenTrailers') : $t('Trucks.ShowHiddenTrucks')"></v-switch>
        </v-flex>
        <v-flex xs12 md3 v-if="driversOOItems.length > 0">
          <v-select
            class="mt-3"
            dense outlined
            item-value="id"
            item-text="name"
            :label="$t('Trucks.DriverOO')"
            :items="driversOOItems"
            v-model="filterDriverOO"
            @change="onFilterDriverOOChanged"
            >
          </v-select>
        </v-flex>
      </v-layout>
      <v-row>
        <template v-for="(item,index) in items">
          <v-flex xs12 md6 lg4 xl3 class="pa-2" :key="index" v-if="showHiddenTrucks || item.status >= 0">
            <TruckCard ref="truckCards"
                       :key="'truck'+index+'_'+item.updateKey"
                       @fetchData="fetchData"
                       :loadsData="item.loadsData"
                       :edit_icons="true"
                       :showTrucksImages="showTrucksImages"
                       :truckData="item"
                       :originalParts="item.originalPartsMap"
                       :notifications="item.notifications"
            />
          </v-flex>
        </template>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TruckCard from './../../components/TruckCard.vue';
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import userFunctionsMixin from './../../mixins/userFunctionsMixin';

export default {
  props: ['responseOverride', 'hideFilters', 'hideTitle', 'hidePagination', 'hideSelect', 'hideReportMode', 'hideTotals'],
  components: { TruckCard },
  mixins: [ fieldAccessRightMixin, userFunctionsMixin ],
  data () {
    return {
      loading: false,
      error: '',
      items: [],
      driversOOItems: [],
      filterDriverOO: 0,
      showTrucksImages: false,
      showHiddenTrucks: false,
      pooling: null
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.$root.contextCreate = () => {
        this.$root.route('/trucks/create' + (this.$route.params.isTrailer ? '/1' : ''));
      };

      this.fetchData();

      this.polling = setInterval(() => {
        // Do not call interval methods if page not visible
        if (!this.$store.getters.isPageVisible) return;
        this.fetchData(false);
        if (this.$refs.truckCards) {
          for (let k in this.$refs.truckCards) {
            if (this.items[k].coordsUpdated) {
              this.$refs.truckCards[k].updateMarker();
            }
          }
        }
        this.$forceUpdate();
      }, this.$store.getters.pullInterval);
    });
  },
  beforeDestroy () {
    clearInterval(this.polling);
    this.$root.contextCreate = () => {};
  },
  methods: {
    isCoordsAreChangedByVin (newTruckData) {
      for (let k in this.items) {
        if (!newTruckData.vin || !this.items[k].vin) continue;
        if (newTruckData.vin.toLowerCase() === this.items[k].vin.toLowerCase()) {
          if (newTruckData.lat !== this.items[k].lat ||
              newTruckData.lng !== this.items[k].lng) {
            return true;
          }
          break;
        }
      }
      return false;
    },
    onFilterDriverOOChanged (event) {
      this.fetchData();
    },
    onShowHiddenTrucksSwitchChange (event) {
      this.fetchData();
    },
    isTruckHidden (id) {
      return this.$root.getLocalStorageItem('truckHidden' + id);
    },
    async fetchData (showLoading = true) {
      let i = 0;
      let _items = [];
      let item = {};
      let notifications = [];
      let trucksNotificationsResults = {};
      if (!this.isTrucksViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }
      if (showLoading) {
        this.loading = true;
      }
      let response = {};
      if (this.responseOverride) {
        response = this.responseOverride;
      }
      else {
        response = await this.$root.getTrucksList(false, this.filterDriverOO, this.$route.params.isTrailer);
      }

      if (response.status === 'ok') {
        _items = [];
        trucksNotificationsResults = await this.$root.getTrucksNotificationsData();
        this.driversOOItems = response.driversOOItems || [];
        if (response.driversOOItems.length > 0) {
          this.driversOOItems.unshift({ id: 0, name: 'No User' });
        }

        for (i = 0; i < response.result.length; i++) {
          if (this.isTruckHidden(response.result[i].id) && !this.showHiddenTrucks) continue;
          item = response.result[i];
          item.coordsUpdated = false;
          item = this.$root.insertTruckTelemetry(item);
          item['loadsData'] = this.$root.insertLoadsData(item);
          notifications = [];
          if (trucksNotificationsResults.status === 'ok' && trucksNotificationsResults.results.hasOwnProperty(item['id'])) {
            notifications = trucksNotificationsResults.results[item['id']];
          }
          item['notifications'] = await this.$root.getTuckNotifications(item, this.$store.getters['globalSettingsFields'], notifications);

          if (this.isCoordsAreChangedByVin(item)) {
            item.updateKey = new Date().getTime();
            item.coordsUpdated = true;
          }

          item.originalPartsMap = {};
          if (response.result[i].hasOwnProperty('originalParts')) {
            for (let j = 0; j < response.result[i].originalParts.length; j++) {
              const part = response.result[i].originalParts[j];
              if (!part.hasOwnProperty('maintenanceType')) continue;
              if (!item.originalPartsMap.hasOwnProperty(part.maintenanceType)) {
                item.originalPartsMap[part.maintenanceType] = { maintenanceType: part.maintenanceType, parts: [] };
              }
              item.originalPartsMap[part.maintenanceType].parts.push(part);
            }
          }

          _items.push(item);
        }
        this.items = _items;
      }
      this.loading = false;
    }
  }
};
</script>
